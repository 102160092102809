<template>

  <div>

    <!-- loading state -->
    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>

    <!-- content section -->
    <template v-else>
      <!-- empty state-->
      <template>

      </template>

      <!-- data section -->
      <template>

        <tareas-filters/>

        <b-card
          no-body
          class="mb-0"
        >
          <!-- top -->
          <div class="m-2">

            <b-row class="mb-1">
              <!-- per page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Mostrar</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>registros</label>
              </b-col>
              <!-- search -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">

                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Buscar..."
                  />


                </div>
              </b-col>

            </b-row>

            <!-- table -->
            <b-table
              class="position-relative"
              :items="filteredData"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              responsive
              show-empty
            >

              <!-- numero secuencial -->
              <template #cell(frmSecuencial)="data">
                <b-button variant="link" class="p-0" @click="handleOpenFormulario(data.item.frmUuid)">
                  #{{ data.value }}
                </b-button>
              </template>

              <!-- Column: Fecha creacion -->
              <template #cell(frmFecha)="data">
                <span class="text-capitalize">
                {{ getFormatDate(data.value) }}
                </span>
              </template>

              <!-- Column: Nombre colaborador -->
              <template #cell(empNombres)="data">
                {{ data.item.empApellidos }}, {{ data.item.empNombres }}
              </template>

              <!-- Column: Fecha fecha limite -->
              <template #cell(frmFechaLimite)="data">
                <span class="text-capitalize">
                {{ getFormatDateTime(data.value) }}
                </span>
              </template>

              <!-- Column: Estado -->
              <template #cell(frmEstado)="data">
                <template v-if="data.item.frmAnulado">
                  <b-badge
                    :id="`form-anulada-${data.item.frmId}`"
                    pill
                    variant="light-danger"
                  >Anulada</b-badge>
                  <b-tooltip
                    :target="`form-anulada-${data.item.frmId}`"
                    placement="top"
                  >
                    <p class="mb-0">
                      {{ data.item.frmAnulado}}
                    </p>
                  </b-tooltip>
                </template>
                <template v-else>
                  <b-badge
                    pill
                    :variant="getLightFormStatusVariant(data.item)"
                    class="text-capitalize"
                  >
                    {{ getTaskStatusName(data.item) }}
                  </b-badge>
                </template>
              </template>

            </b-table>

              <!-- pagination -->
              <div class="mx-2 mb-2">
                  <b-row>
                      <b-col
                              cols="12"
                              sm="6"
                              class="d-flex align-items-center justify-content-center justify-content-sm-start"
                      >
                          <span class="text-muted">{{ totalRecords }} registros encontrados</span>
                      </b-col>
                      <b-col
                              cols="12"
                              sm="6"
                              class="d-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                          <b-pagination
                                  v-model="currentPage"
                                  :total-rows="totalRecords"
                                  :per-page="perPage"
                                  first-number
                                  last-number
                                  class="mb-0 mt-1 mt-sm-0"
                                  prev-class="prev-item"
                                  next-class="next-item"
                          >
                              <template #prev-text>
                                  <feather-icon
                                          icon="ChevronLeftIcon"
                                          size="18"
                                  />
                              </template>
                              <template #next-text>
                                  <feather-icon
                                          icon="ChevronRightIcon"
                                          size="18"
                                  />
                              </template>
                          </b-pagination>

                      </b-col>
                  </b-row>

              </div>

          </div>

        </b-card>

      </template>



    </template>


  </div>

</template>

<script>
import {BBadge, BButton, BCard, BCol, BFormInput, BPagination, BRow, BSpinner, BTable, BTooltip} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  resolveFormatoFecha,
  resolveFormatoFechaHora,
  resolveTaskStatusName,
  resolveLightFormStatusVariant,
  resolvePerPageOptions,
} from '@/modules/common/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GestionTareas',
  components: {
      BPagination,
    BBadge,
    BTooltip,
    BButton,
    BTable,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    BCard,
    BSpinner,
    TareasFilters: () => import('@/modules/ejecutante/views/tareas/TareasFilters.vue')
  },
  data(){
    return {
      errors: [],
      isLoading: false,
      isBusy: false,

      perPage: 10,
      perPageOptions: resolvePerPageOptions(),
      currentPage: 1,
      totalRecords: 0,
      searchQuery: '',
      fields: [
        { key: 'frmSecuencial', label: 'Secuencial' },
        { key: 'ftyCodigo', label: 'Tipo' },
        { key: 'frmFecha', label: 'Fecha' },
        { key: 'empNombres', label: 'Empleado' },
        { key: 'frmFechaLimite', label: 'Límite' },
        { key: 'frmEstado', label: 'Estado' },
      ]


    }
  },

  computed: {

    ...mapGetters('tasks-module', ['fetchTasks']),

    filteredData(){
        //return this.fetchTasks(this.searchQuery)

        const data =  this.fetchTasks( this.searchQuery )
        this.totalRecords = data.length
        return data

    },

  },


  methods:{

    ...mapActions('tasks-module', ['getTasksLatestStatus']),

    // cargar datos de las tareas
    async loadData(){
      try {
        this.isBusy = true
        await this.getTasksLatestStatus()
      } catch (error) {
        console.log("UNEXPECTED", error)
      } finally {
        this.isBusy = false
      }

    },

    // procesar peticion para abrir formulario
    handleOpenFormulario(uuid){
      this.$router.push({name: 'tareas-open', query: { key: uuid }})
    },

    // formato para fecha
    getFormatDate( date ){
      return resolveFormatoFecha(date)
    },
    // formato para datetime
    getFormatDateTime( date ){
      return resolveFormatoFechaHora(date)
    },
    // nombre de la tarea
    getTaskStatusName( item ){
      const status = {
        status: item.frmEstado,
        latestStatus: item.fstStatus
      }
      return resolveTaskStatusName( status )
    },

    // estilo para el estado
    getLightFormStatusVariant( item ){
      const status = {
        status: item.frmEstado,
        latestStatus: item.fstStatus
      }
      return resolveLightFormStatusVariant(status)
    },



  },
  async created() {
    await this.loadData()
  }

}
</script>

<style scoped>

</style>
